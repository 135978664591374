import { useState, useEffect, useRef, useMemo } from "react";
import { useParams, useLoaderData, Link, useSearchParams } from "react-router-dom";

import { ArrowRight, ChevronDown, ChevronRight, Code, ExternalLink, File, FileText, Globe, Lock, Package, Sliders, Terminal, Tool, User, AlertTriangle, Key, Cpu } from "react-feather";
import {
  DetailedPackageDeploymentSchema,
  DetailedPackageFileSchema,
  PackageEndpointSchema,
  PackageFileSchema,
  PackageSchema
} from "../../utils/types";
import PackageCard from "./PackageCard";

import API, { ErrorWithDetails } from "../../utils/api";
import { PackageDetailsPageLoaderData } from "./PackageDetailsPageLoader";
import { InternalLink } from "../../components/internal-link/InternalLink";
import "./PackageDetailsPage.scss";
import { MiniEditor } from "../../components/editor/MiniEditor";
import { Helmet } from "react-helmet-async";
import { Breadcrumb, useBreadcrumbs } from "../../context/BreadcrumbsContext";
import { AlertModal } from "../../components/modals/AlertModal";
import { ContentButton } from "../../components/button/ContentButton";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import { toast } from "../../services/toast";

export function PackageEndpoint({
  endpoint,
  link = false
}: {
  endpoint: PackageEndpointSchema,
  link?: boolean
}) {

  return (
    <div data-component="PackageEndpoint">
      <div className="package-endpoint-header">
        <div className="package-endpoint-header-icon">
          <Tool />
        </div>
        <div className="package-endpoint-name">
          <div className="package-endpoint-title">
            {link && (
              <InternalLink to={`/packages/${endpoint.function_info.package.name}/${endpoint.function_info.package.version}/${endpoint.function_info.filename}?method=${endpoint.function_info.method}`}>
                {endpoint.function_info.title}
              </InternalLink>
            )}
            {!link && (
              <span>{endpoint.function_info.title}</span>
            )}
          </div>
          {endpoint.function_info.title !== `${endpoint.function_info.method} ${endpoint.function_info.route}` && (
            <div className="package-endpoint-route">
              <span className="package-endpoint-method">
                <span className={endpoint.function_info.method}>{endpoint.function_info.method}</span>
              </span>
              <span className="route-name">
                {endpoint.function_info.route}
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export function PackageEndpointDetails({
  endpoint,
}: {
  endpoint: PackageEndpointSchema
}) {

  const toolArguments = useMemo(() => {
    const props = endpoint.function_info.parameters.properties;
    const lines = [];
    for (const [ key, value ] of Object.entries(props)) {
      const v = value as any;
      let type = v.type || 'any';
      if (type === 'array') {
        type = `list of ${v.items.type}s`;
      }
      const output = (
        <div className="tool-argument" key={key}>
          <div className="tool-argument-row">
            <div className="tool-argument-name">{key}</div>
            <div className="tool-argument-type">&nbsp;({type})</div>
            {!v.hasOwnProperty('default') && (
              <>
                <span className="middot">&middot;</span>
                <div className="tool-argument-required">required</div>
              </>
            )}
            {v.hasOwnProperty('default') && (
              <>
                <span className="middot">&middot;</span>
                <div className="tool-argument-default">
                  optional, default <code>{JSON.stringify(v.default)}</code>
                </div>
              </>
            )}
          </div>
          {v.description && (
            <div className="tool-argument-row sub">
              <div className="tool-argument-description">{v.description}</div>
            </div>
          )}
          {(
            v.hasOwnProperty('minimum') ||
            v.hasOwnProperty('maximum')
          ) && (
            <div className="tool-argument-row sub">
              {v.minimum && (
                <>
                  <span className="middot">&middot;</span>
                  <div className="tool-argument-default">
                    min <code>{JSON.stringify(v.minimum)}</code>
                  </div>
                </>
              )}
              {v.maximum && (
                <>
                  <span className="middot">&middot;</span>
                  <div className="tool-argument-default">
                    max <code>{JSON.stringify(v.maximum)}</code>
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      );
      lines.push(output);
    }
    return lines;
  }, [endpoint.function_info.parameters.properties]);

  const toolDescription = useMemo(() => {
    const description = endpoint.function_info.description.trim();
    const lines = description.length > 0 ? description.split('\n') : [];
    const output = [];
    for (const line of lines) {
      output.push(<div className="tool-description-line" key={line}>{line}</div>);
    }
    return output;
  }, [endpoint.function_info.description]);

  return (
    <div data-component="PackageEndpointDetails">
      <div className="package-single-endpoint">
        <div className={`description ${!toolDescription.length ? 'empty' : ''}`}>
          {toolDescription.length > 0 ? toolDescription : 'No description available'}
        </div>
      </div>
      <div className="package-endpoints-header">
        <Terminal />
        <span>Arguments</span>
        <span className="label middot">&middot;</span>
        <span className="label">Requests to this tool can provide the following arguments</span>
      </div>
      <div className="package-single-endpoint">
        <div className={`parameters ${!toolArguments.length ? 'empty' : ''}`}>
          {toolArguments.length > 0 ? toolArguments : 'No arguments required'}
        </div>
      </div>
    </div>
  );

};

export function PackageEndpointDeveloperNotes({
  endpoint,
}: {
  endpoint: PackageEndpointSchema
}) {

  const [ parametersOptionName, setParametersOptionName ] = useState('Typescript');

  const functionArguments = useMemo(() => {
    const params = { ...endpoint.function_info.parameters };
    const props = params.properties;
    const required = Object.keys(props).filter(key => !props[key].hasOwnProperty('default'));
    return JSON.stringify({ ...params, required }, null, 2);
  }, [endpoint.function_info.parameters]);

  const functionInterface = useMemo(() => {
    const props = endpoint.function_info.parameters.properties;
    const lines = [`interface FunctionArguments {`];
    for (const [ key, value ] of Object.entries(props)) {
      const v = value as any;
      let type = v.type || 'any';
      if (type === 'array') {
        type = `${v.items.type}[]`;
      }
      if (v.description) {
        lines.push(`  /** ${v.description} */`);
      }
      lines.push(`  ${key}${v.default ? '?' : ''}: ${type};${v.default ? ` // = ${JSON.stringify(v.default)}` : ''}`);
    }
    lines.push('}');
    return lines.join('\n');
  }, [endpoint.function_info.parameters.properties]);

  return (
    <div data-component="PackageEndpointDeveloperNotes">
      <h1>
        <Code />
        <span>Developer notes</span>
      </h1>
      <MiniEditor
        display="package"
        label="Request parameters"
        copyable={true}
        language="typescript"
        value={functionInterface}
        selectedOptionName={parametersOptionName}
        options={[
          {
            name: 'TypeScript',
            language: 'typescript',
            value: functionInterface,
          },
          {
            name: 'JSON Schema',
            language: 'json',
            value: functionArguments,
          }
        ]}
        onSelectOption={(option) => setParametersOptionName(option.name)}
        readonly={true}
      />
    </div>
  );

};

export default function PackageDetailsPage() {

  const {
    packageName,
    version,
    environment,
    pkg,
    packageVersion,
    packageDeployment,
    filename,
    file,
    fileEndpoint,
    methods
  } = useLoaderData() as PackageDetailsPageLoaderData;

  const [ searchParams ] = useSearchParams();
  const method = searchParams.get('method');

  const { organization } = useAuth();
  const { updateBreadcrumbs } = useBreadcrumbs();
  const navigate = useNavigate();

  const [ viewPkg, setViewPkg ] = useState<PackageSchema>(pkg);

  const requiredKeys = useMemo(() => {
    return packageDeployment?.required_keys || [];
  }, [packageDeployment]);

  let title = viewPkg.agent
    ? viewPkg.agent.agentConfigs?.[0]?.name.split('/').slice(-1)[0]
    : viewPkg.display_title;
  let displayTitle = title;
  let packageTitle = displayTitle;
  if (fileEndpoint) {
    title = `${fileEndpoint.function_info.title} | ${title} | Endpoint`;
    displayTitle = fileEndpoint.function_info.title;
  } else if (filename) {
    title = `${filename.split('/').slice(-1)[0]} | ${title} | File`;
    displayTitle = filename.split('/').slice(-1)[0];
  } else {
    title = `${title}${version || environment ? ` (${version || environment})` : ''} | Package`;
  }
  const description = pkg?.description || 'View this package on Funct';
  let rootPath = window.location.pathname;
  if (filename &&rootPath.endsWith(`/${filename}`)) {
    rootPath = rootPath.slice(0, -(filename.length + 1));
  }
  if (rootPath.endsWith(version)) {
    rootPath = rootPath.slice(0, -(version.length + 1));
  }
  if (rootPath.endsWith(environment)) {
    rootPath = rootPath.slice(0, -(environment.length + 1));
  }

  useEffect(() => {
    if (filename) {
      updateBreadcrumbs([
        {
          icon: User,
          label: `@${viewPkg.organization.name}`,
          path: `/packages/@${viewPkg.organization.name}`
        },
        {
          icon: viewPkg.display_name.startsWith('@') ? Package : viewPkg.agent ? Cpu : Lock,
          label: packageTitle,
          path: rootPath
        },
        {
          icon: viewPkg.display_name.startsWith('@') ? Package : viewPkg.agent ? Cpu : Lock,
          label: `${packageTitle} (${version || environment})`,
          path: `${rootPath}/${version || environment}`
        },
        {
          icon: fileEndpoint ? Tool : File,
          label: displayTitle,
          path: `${rootPath}/${version || environment}/${filename}` + (method ? `?method=${method}` : '')
        }
      ]);
    } else {
      const breadcrumbs: Breadcrumb[] = [
        {
          icon: User,
          label: `@${viewPkg.organization.name}`,
          path: `/packages/@${viewPkg.organization.name}`
        },
        {
          icon: viewPkg.display_name.startsWith('@') ? Package : viewPkg.agent ? Cpu : Lock,
          label: packageTitle,
          path: rootPath
        }
      ];
      if (version || environment) {
        breadcrumbs.push({
          icon: viewPkg.display_name.startsWith('@') ? Package : viewPkg.agent ? Cpu : Lock,
          label: `${packageTitle} (${version || environment})`,
          path: `${rootPath}/${version || environment}`
        });
      }
      updateBreadcrumbs(breadcrumbs);
    }
    return () => {
      updateBreadcrumbs([]);
    };
  }, [rootPath, fileEndpoint, filename, packageTitle, displayTitle, viewPkg]);

  const packageFilesDisplay = useMemo(() => {
    return packageDeployment?.packageFiles
      .sort((fa, fb) => {
        const da = fa.filename.indexOf('/') > -1;
        const db = fb.filename.indexOf('/') > -1;
        return da === db
          ? fa.filename === fb.filename
            ? 0
            : fa.filename > fb.filename
              ? 1
              : -1
          : da > db
            ? -1
            : 1;
      })
      .map(curFile => {
        return (
          <tr
            key={curFile.filename}
            className={curFile.filename === file?.filename ? 'selected' : ''}
            >
            <td className="icon">
              <File />
            </td>
            <td>
              <InternalLink to={`/packages/${packageName}/${packageVersion?.version || packageVersion?.environment}/${curFile.filename}`}>
                {curFile.filename}
              </InternalLink>
            </td>
            {false && <td className="content-type">
              {curFile.content_type}
            </td>}
            <td className="size">
              {curFile.size} B
            </td>
          </tr>
        );
      });
  }, [packageDeployment?.packageFiles]);

  const [isArchiving, setIsArchiving] = useState(false);
  const [showArchiveAlert, setShowArchiveAlert] = useState(false);

  const archivePackage = async () => {
    setIsArchiving(true);
    try {
      await API.del('v1/packages', { 
        name: pkg?.display_name,
        environment: packageVersion?.environment || environment,
        version: packageVersion?.version || null
      });
      toast.message({ type: 'success', message: `Environment archived`, duration: 1000 });
      // Redirect to package list after archive
      if (viewPkg.packageVersions.length > 1) {
        const packageVersions = viewPkg.packageVersions
          .filter(v => v.environment !== packageVersion?.environment && v.version !== packageVersion?.version);
        navigate(`/packages/${viewPkg.display_name}/${packageVersions[0].version || packageVersions[0].environment}`);
      } else {
        navigate(`/packages/@${viewPkg.organization.name}`);
      }
    } catch (e) {
      const message = e instanceof Error ? e.message : 'Failed to archive environment';
      toast.message({ type: 'error', message, duration: 5000 });
    }
    setIsArchiving(false);
  };

  return (
    <div data-component="PackageDetailsPage">
      <Helmet>
        <title>{title} | Funct</title>
        <meta name="description" content={description} />
      </Helmet>
      {pkg && (
        <div className="packages-list">
          <PackageCard
            key={viewPkg.display_name}
            pkg={pkg}
            environment={environment}
            version={version}
            mode="page"
            onEdit={(pkg) => setViewPkg(pkg)}
          />
          {packageVersion && packageDeployment && (
            <div className="package-details">
              {!filename && (
                <>
                  <div className="package-endpoints-header">
                    <span>Endpoints</span>
                    <span className="label middot">&middot;</span>
                    <span className="label">All endpoints are available as tools for your agent</span>
                  </div>
                  <div className="package-endpoints">
                    {packageDeployment.packageEndpoints.map(endpoint => {
                      return (
                        <PackageEndpoint
                          key={endpoint.function_info.name}
                          endpoint={endpoint}
                          link={true}
                        />
                      )
                    })}
                  </div>
                </>
              )}
              {!filename && (
                <>
                  {requiredKeys.length > 0 && (
                    <>
                      <div className="package-files-header">
                        <span>Required keys</span>
                        <span className="label middot">&middot;</span>
                        <span className="label">Your keychain must contain the following keys to use this package</span>
                      </div>
                      <div className="package-keychain-keys">
                        {requiredKeys.map((key, index) => (
                          <div className="package-keychain-key" key={index}>
                            <Key />
                            <div className="key-entry">
                              <span className="key-name">{key.name}</span>
                              <span className="key-description">{key.description}</span>
                            </div>
                          </div>
                        ))}
                      </div>
                    </>
                  )}
                  <div className="package-files-header">
                    <span>Codebase</span>
                    <span className="label middot">&middot;</span>
                    <span className="label">Public packages are open source, while agent and private packages are not</span>
                  </div>
                  <div className="package-files">
                    <table>
                      <tbody>
                        {!packageDeployment.packageFiles.length && (
                          <tr>
                            <td className="package-files-empty">
                              This type of package is not open source.
                            </td>
                          </tr>
                        )}
                        {packageFilesDisplay}
                      </tbody>
                    </table>
                  </div>
                </>
              )}

              {fileEndpoint && (
                <>
                  <div className="package-endpoints-header">
                    <Tool />
                    <span>{fileEndpoint.function_info.route}</span>
                    <span className="label middot">&middot;</span>
                    {
                      methods.map((method, index) => {
                        const selected = method === fileEndpoint.function_info.method;
                        return (
                          <span className="container" key={method}>
                            {selected && (
                              <span>{method}</span>
                            )}
                            {!selected && (
                              <span className="label">
                                <InternalLink to={`/packages/${packageName}/${packageVersion?.version || packageVersion?.environment}/${fileEndpoint.function_info.filename}?method=${method}`}>
                                  {method}
                                </InternalLink>
                              </span>
                            )}
                            {index !== methods.length - 1 && <span className="label">&middot;</span>}
                          </span>
                        )
                      })
                    }
                  </div>
                  <PackageEndpointDetails endpoint={fileEndpoint} />
                </>
              )}

              {file && (
                <div className="package-single-file">
                  {!fileEndpoint && (
                    <div className="package-single-file-header">
                      <File />
                      <span>{file.filename}</span>
                    </div>
                  )}
                  {fileEndpoint && (
                    <div className="package-single-file-header">
                      <File />
                      <span>{file.filename}</span>
                      <span className="label middot">&middot;</span>
                      <span className="label">Endpoint file</span>
                    </div>
                  )}
                  <div className="package-file-details">
                    <MiniEditor
                      language="javascript"
                      value={file.text_content || ''}
                      readonly={true}
                    />
                  </div>
                </div>
              )}

              {fileEndpoint && (
                <PackageEndpointDeveloperNotes endpoint={fileEndpoint} />
              )}

              {!filename && packageVersion && (organization?.name === viewPkg.organization.name) && (
                <>
                  <div className="package-danger-header">
                    <AlertTriangle />
                    <span>Danger zone</span>
                    <span className="label middot">&middot;</span>
                    <span className="label">As package administrator, you can take destructive actions</span>
                  </div>
                  <div className="package-danger-zone">
                    <div className="danger-action">
                      <div className="danger-action-description">
                        <div className="title">
                          Archive environment or version
                        </div>
                        <div className="description">
                          Package version will no longer be available for use with agents or via the API.
                        </div>
                      </div>
                      <ContentButton
                        color="red"
                        onClick={() => setShowArchiveAlert(true)}
                        loading={isArchiving}
                      >
                        Archive {packageVersion?.version || packageVersion?.environment}
                      </ContentButton>
                    </div>
                  </div>
                  {showArchiveAlert && (
                    <AlertModal
                      message={`Archive the ${packageVersion?.version || packageVersion?.environment || version} version of this package?\nThis action cannot be undone.`}
                      onClose={() => setShowArchiveAlert(false)}
                      onConfirm={async (complete) => {
                        await archivePackage();
                        complete();
                      }}
                      onCancel={() => {}}
                    />
                  )}
                </>
              )}

            </div>
          )}
        </div>
      )}
    </div>
  );

};