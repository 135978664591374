import { LoaderFunctionArgs } from "react-router-dom";
import API, { ErrorWithDetails } from "../../utils/api";
import { PackageSchema } from "../../utils/types";

export interface PackagesPageLoaderData {
  organizationName: string;
  packages: PackageSchema[];
};

export default function PackagesPageLoader() {
  return async ({ params, request } : LoaderFunctionArgs) => {

    const { org } = params;
    let organizationName = org;
    if (organizationName?.startsWith('@')) {
      organizationName = organizationName.slice(1);
    }

    const packageParams: { [key: string]: any } = {};
    if (organizationName) {
      packageParams.organization = organizationName;
      packageParams.show_agent = true;
      packageParams.show_private = true;
    }

    const packagesResponse = await API.get(
      'v1/packages',
      packageParams
    );
    const packages = (packagesResponse?.data || []) as PackageSchema[];
    return {
      organizationName,
      packages
    };
  
  }
};