import { useState, useEffect } from "react";
import { useLoaderData, useRevalidator, useNavigate, useLocation } from "react-router-dom";

import { PackageSchema } from "../../utils/types";
import PackageCard from "./PackageCard";
import { PackagesPageLoaderData } from "./PackagePageLoader";

import API, { ErrorWithDetails } from "../../utils/api";

import "./PackagesPage.scss";
import { Package, Plus, User } from "react-feather";
import { useBreadcrumbs } from "../../context/BreadcrumbsContext";
import { Helmet } from "react-helmet-async";

import { EMPTY_PACKAGE } from "../dashboard/agents-chat/code/AgentsCode";
import { ContentButton, ContentButtonGroup } from "../../components/button/ContentButton";
import { InternalLink } from "../../components/internal-link/InternalLink";
import { useAuth } from "../../context/AuthContext";
import { CreatePackageModal } from './CreatePackageModal';

export default function PackagesPage() {

  const { user, organization } = useAuth()
  const { organizationName, packages } = useLoaderData() as PackagesPageLoaderData;
  const { breadcrumbs, updateBreadcrumbs } = useBreadcrumbs();
  const [showCreateModal, setShowCreateModal] = useState(false);

  let displayTitle = 'Packages';
  if (organizationName) {
    displayTitle = `${organizationName} | Packages`;
  }
  const description = `Explore packages created by ${organizationName}`;

  const createPackage = async (name: string, environment: string) => {
    const saveFiles = EMPTY_PACKAGE;
    const base64Files: {[key: string]: string} = {};
    for (const pathname in saveFiles) {
      base64Files[pathname] = saveFiles[pathname].value._base64;
    }
    const params = {
      name,
      environment,
      base64Files,
      publishError: false,
      autoInstall: true,
      _stream: {
        log: ({ event, data }: { event: string, data: any }) => {
          // Push deploy logs to console
          console.log(data);
        }
      }
    } as {[key: string]: any};
    const result = await API.post(
      'v1/packages',
      params
    );
    return result;
  }

  useEffect(() => {
    if (organizationName) {
      updateBreadcrumbs([
        {
          icon: User,
        label: `@${organizationName}`,
        path: `/packages/@${organizationName}`
        }
      ]);
    } else {
      updateBreadcrumbs([]);
    }
    return () => {
      updateBreadcrumbs([]);
    }
  }, [organizationName]);

  return (
    <div data-component="PackagesPage">
      <Helmet>
        <title>{displayTitle}</title>
        <meta name="description" content={description} />
      </Helmet>
      <div className="packages-title">
        <div className="heading">
          {organizationName ? `Packages by @${organizationName}` : 'Package registry'}
        </div>
        {!organizationName && (
          <div className="subheading">
            Packages extend the capabilities of your agent.
          </div>
        )}
      </div>
      {user && (
        <div className="packages-actions">
          <div className="packages-actions-row">
            <ContentButtonGroup>
              <ContentButton
                icon={Package}
                active={!organizationName}
                color="grey"
                to="/packages"
              >
                Public packages
              </ContentButton>
              <ContentButton
                icon={User}
                active={organizationName === organization?.name}
                color="grey"
                to={`/packages/@${organization.name}`}
              >
                My packages
              </ContentButton>
            </ContentButtonGroup>
            <div className="spacer" />
            <ContentButton
              color="blue"
              icon={Plus}
              onClick={() => setShowCreateModal(true)}
            >
              Create package
            </ContentButton>
          </div>
        </div>
      )}
      <div className="packages-list">
        {packages.map(pkg => {
          return (
            <PackageCard
              key={pkg.display_name}
              pkg={pkg}
            />
          );
        })}
      </div>
      {showCreateModal && (
        <CreatePackageModal
          onClose={() => setShowCreateModal(false)}
          createPackage={createPackage}
        />
      )}
    </div>
  );

};